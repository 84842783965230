import { IAudioItem } from '../../talks/audio-item.interface'

export const Talk20250210: IAudioItem = {
  category: 'buddhist',
  date: '2025-02-10',
  filename: '2025-02-10-sigalovada-east-parent-child.mp3',
  key: 'e333cb82-7126-4a8d-a489-3395adebcdf7',
  published: '2025-02-10',
  title: 'Sigalovada Sutta: Parent/Child Relationships (Eastern Direction)',
  speaker: 'Shi Wayne',
  location: 'buddha-center',
  notes: `
<h5>Practice Homework</h5>
<p>
Ask yourself, "In what way do I think or act most like my parents?"  
</p>
<p>
The answer might be a while in arising; be patient.  
When it does, write it down, good or bad.  
Write it down.  
Multiples are okay, good or bad.
</p>
`
}
