import { IAudioItem } from '../../talks/audio-item.interface'

export const Talk20250113: IAudioItem = {
  category: 'buddhist',
  date: '2025-01-13',
  filename: '2025-01-13-sigalovada-setting-stage.mp3',
  key: '1ff859e9-c62f-44cd-acf7-382ad086ac3c',
  published: '2025-02-19',
  title: 'Sigalovada Sutta: Setting The Stage',
  speaker: 'Shi Wayne',
  location: 'buddha-center',
}
