import { IAudioItem } from '../../talks/audio-item.interface'

export const Talk20250124: IAudioItem = {
  category: 'buddhist',
  date: '2025-01-24',
  filename: '2025-01-24-invasion-of-holy-men.mp3',
  key: '485a4ea0-e4ff-4c2c-a2a7-32d71898733f',
  published: '2025-02-05',
  title: 'Invasion Of The Holy Men (Kalama Sutta)',
  speaker: 'Shi Wayne',
  location: 'buddha-center',
}
