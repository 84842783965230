import { IAudioItem } from '../../talks/audio-item.interface'

export const Talk20250117: IAudioItem = {
  category: 'buddhist',
  date: '2025-01-17',
  filename: '2025-01-17-sigala-buddha-wealth.mp3',
  key: '3cf59081-c95c-4b83-ace0-2435ced88cad',
  published: '2025-02-05',
  title: 'Sigalovada Sutta: Sigala, The Buddha And Wealth',
  speaker: 'Shi Wayne',
  location: 'buddha-center',
}
