import { IAudioItem } from '../../talks/audio-item.interface'

export const Talk20240628: IAudioItem = {
  category: 'buddhist',
  date: '2024-06-28',
  filename: '2024-06-28-bodhiccita.mp3',
  key: 'eb41c909-a225-4f90-8b9d-c6e2c69872ab',
  published: '2024-07-10',
  title: 'Bodhicitta',
  location: 'buddha-center',
}
