import React from 'react'

import { DharmaTalkList } from './dharma-talk-list'
import { LocationLink } from './location-link'
import { PreceptClassList2023 } from './precept-class-list-2023'
import { RecentlyAddedDharmaTalks } from './recently-added-dharma-talks'

export function BuddhistPage() {
  return (
    <>
      <h1 className="display-1">Buddhist Topics</h1>
      <p>
        This is a shared project of members of the sangha of the
        {' '}<LocationLink location={'buddha-center'}/>{' '}
        in SecondLife.
      </p>
      <p>
        These talks are shared for the sangha
        who can't attend due to work, or otherwise missed a talk.
        A fraction of past talks are here.
        There's quite a backlog to be processed.
        Please be patient.
      </p>

      <RecentlyAddedDharmaTalks/>

      <PreceptClassList2023/>

      <DharmaTalkList/>

    </>
  )
}
