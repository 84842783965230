import { IAudioItem } from '../../talks/audio-item.interface'

export const Talk20250120: IAudioItem = {
  category: 'buddhist',
  date: '2025-01-20',
  filename: '2025-01-20-sigalovada-finances.mp3',
  key: '57b86fec-5745-4b07-9603-b78a1a427546',
  published: '2025-02-05',
  title: 'Sigalovada Sutta: Finances',
  speaker: 'Shi Wayne',
  location: 'buddha-center',
}
