import { IAudioItem } from '../../talks/audio-item.interface'

export const Talk20250214: IAudioItem = {
  category: 'buddhist',
  date: '2025-02-14',
  filename: '2025-02-14-sigalovada-south-teacher-student.mp3',
  key: '3ebc2cad-df6b-4aa8-97cf-1d9cc2e5b311',
  published: '2025-02-17',
  title: 'Sigalovada Sutta: Secular Teacher/Student Relationships (Southern Direction)',
  speaker: 'Shi Wayne',
  location: 'buddha-center',
}
