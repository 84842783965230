import React from 'react'

import { LocationType } from '../../lib/talks/LocationType'

export function LocationLink(params: { location: LocationType }) {
  return (
    <a
      href="http://maps.secondlife.com/secondlife/Naberrie/107/149/53"
      target="_blank"
      rel="noreferrer"
    >
      Buddha Center
    </a>
  )
  // switch (params.location) {
  //   case 'buddha-center':
  //     return (
  //       <a
  //         href="http://maps.secondlife.com/secondlife/Naberrie/82/153/53"
  //         target="_blank"
  //         rel="noreferrer"
  //       >
  //         Buddha Center
  //       </a>
  //     )
  //   case 'sangha-circle':
  //     return (
  //       <a
  //         href="http://maps.secondlife.com/secondlife/Naberrie/82/153/53"
  //         target="_blank"
  //         rel="noreferrer"
  //       >
  //         Buddha Center
  //       </a>
  //     )
  //   default:
  //     return null
  // }
}
