import { IAudioItem } from '../../talks/audio-item.interface'

export const Talk20250207: IAudioItem = {
  category: 'buddhist',
  date: '2025-02-07',
  filename: '2025-02-07-sigalovada-commitment-types.mp3',
  key: 'e2acfc1b-c9e4-4d20-ad8f-44239798e78f',
  published: '2025-02-07',
  title: 'Sigalovada Sutta: Types of Commitments',
  speaker: 'Shi Wayne',
  location: 'buddha-center',
}
