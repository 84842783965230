import { IAudioItem } from '../../talks/audio-item.interface'

export const Talk20250131: IAudioItem = {
  category: 'buddhist',
  date: '2025-01-31',
  filename: '2025-01-31-sigalovada-friends.mp3',
  key: '19f483ba-3233-4107-8289-0d35fec77674',
  published: '2025-02-05',
  title: 'Sigalovada Sutta: Friends',
  speaker: 'Shi Wayne',
  location: 'buddha-center',
}
