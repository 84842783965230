import { IAudioItem } from '../../talks/audio-item.interface'

export const Talk20250203: IAudioItem = {
  category: 'buddhist',
  date: '2025-02-03',
  filename: '2025-02-03-sigalovada-friends-consequences.mp3',
  key: '847f3a00-f127-4cf1-abad-eb45e19cc78b',
  published: '2025-02-04',
  title: 'Sigalovada Sutta: Friends Recognized, Consequences Realized',
  speaker: 'Shi Wayne',
  location: 'buddha-center',
}
