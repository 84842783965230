import { IAudioItem } from '../../talks/audio-item.interface'

export const Talk20240614: IAudioItem = {
  category: 'buddhist',
  date: '2024-06-14',
  filename: '2024-06-14-what-we-do-matters.mp3',
  key: 'bc600c29-1377-4ddf-a7f8-ecc7b4c47da0',
  published: '2024-06-15',
  title: 'What We Do Matters',
  location: 'buddha-center',
}
