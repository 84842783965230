import { IAudioItem } from '../../talks/audio-item.interface'

export const Talk20240712: IAudioItem = {
  category: 'buddhist',
  date: '2024-07-12',
  filename: '2024-07-12-can-i-do.mp3',
  pdf: '2024-07-12-can-i-do.pdf',
  key: '86227d73-81da-4c3e-8d89-a63940da12ce',
  published: '2024-07-14',
  title: 'Can I Do This?',
  speaker: 'Tvenkel Xuni Zuo',
  location: 'buddha-center',
}
